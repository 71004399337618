.root {
  color:       #FFFFFF;
  padding: 10px;
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.form {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap:               10px;
  column-gap:            5px;
  grid-template-rows:    auto;
}

.layout_sessionDates_A {
  width:           100%;
  display:         flex;
  grid-column:     1/8;
  grid-row:        1;
}

.layout_sessionDates_B {
  width:           100%;
  display:         flex;
  grid-column:     1/10;
  grid-row:        1;
}

.layout_firstname {
  width:           100%;
  display:         flex;
  grid-column:     1/8;
  grid-row:        2;
}

.layout_lastname {
  width:           100%;
  display:         flex;
  grid-column:     8/15;
  grid-row:        2;
}

.layout_email {
  width:           100%;
  display:         flex;
  grid-column:     1/8;
  grid-row:        3;
}

.layout_phoneNumber {
  width:           100%;
  display:         flex;
  grid-column:     8/15;
  grid-row:        3;
}

.layout_birthday_A {
  width:           100%;
  display:         flex;
  grid-column:     1/8;
  grid-row:        4;
}

.layout_birthday_B {
  width:           100%;
  display:         flex;
  grid-column:     1/9;
  grid-row:        4;
}

.layout_gender_A {
  width:           100%;
  display:         flex;
  grid-column:     8/15;
  grid-row:        4;
}

.layout_gender_B {
  width:           100%;
  display:         flex;
  grid-column:     9/15;
  grid-row:        4;
}

.layout_address {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        5;
}

.layout_address2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        6;
}

.layout_zipCode {
  width:           100%;
  display:         flex;
  grid-column:     1/4;
  grid-row:        7;
}

.layout_city {
  width:           100%;
  display:         flex;
  grid-column:     4/10;
  grid-row:        7;
}

.layout_country {
  width:           100%;
  display:         flex;
  grid-column:     10/15;
  grid-row:        7;
}

.layout_identityDocument_A {
  width:           100%;
  display:         flex;
  grid-column:     1/4;
  grid-row:        8;
}

.layout_identityDocument_B {
  width:           100%;
  display:         flex;
  grid-column:     1/7;
  grid-row:        8;
}

.layout_identityDocumentValidity_A {
  width:           100%;
  display:         flex;
  grid-column:     4/9;
  grid-row:        8;
}

.layout_identityDocumentValidity_B {
  width:           100%;
  display:         flex;
  grid-column:     7/15;
  grid-row:        8;
}

.layout_nationality_A {
  width:           100%;
  display:         flex;
  grid-column:     9/15;
  grid-row:        8;
}

.layout_nationality_B {
  width:           100%;
  display:         flex;
  grid-column:     1/8;
  grid-row:        9;
}

.layout_contactRequest_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        8;
}

.layout_contactRequest_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        9;
}

.layout_contactRequest_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        9;
}

.layout_contactRequest_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        10;
}

.layout_termsOfServiceConsent_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        9;
}

.layout_termsOfServiceConsent_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        10;
}

.layout_termsOfServiceConsent_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        10;
}

.layout_termsOfServiceConsent_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        11;
}

.layout_depositConsent_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        10;
}

.layout_depositConsent_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        11;
}

.layout_depositConsent_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        11;
}

.layout_depositConsent_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        12;
}

.layout_button_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        11;
}

.layout_button_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        12;
}

.layout_button_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        12;
}

.layout_button_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/15;
  grid-row:        13;
}

/********

.layout2_sessionDates {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        1;
}

.layout2_sex {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        2;
}

.layout2_firstname {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        3;
}

.layout2_lastname {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        2;
}

.layout_email {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        3;
}

.layout_phoneNumber {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        3;
}

.layout_password {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        4;
}

.layout_password2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        4;
}

.layout_birthday_A {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        5;
}

.layout_birthday_B {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        4;
}

.layout_address_A {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        6;
}

.layout_address_B {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        5;
}

.layout_address2_A {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        7;
}

.layout_address2_B {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        6;
}

.layout_zipCode_A {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        8;
}

.layout_zipCode_B {
  width:           100%;
  display:         flex;
  grid-column:     1/3;
  grid-row:        7;
}

.layout_city_A {
  width:           100%;
  display:         flex;
  grid-column:     3/10;
  grid-row:        8;
}

.layout_city_B {
  width:           100%;
  display:         flex;
  grid-column:     3/10;
  grid-row:        7;
}

.layout_country_A {
  width:           100%;
  display:         flex;
  grid-column:     10/13;
  grid-row:        8;
}

.layout_country_B {
  width:           100%;
  display:         flex;
  grid-column:     10/13;
  grid-row:        7;
}

.layout_identityDocument_A {
  width:           100%;
  display:         flex;
  grid-column:     1/4;
  grid-row:        9;
}

.layout_identityDocument_B {
  width:           100%;
  display:         flex;
  grid-column:     1/4;
  grid-row:        8;
}

.layout_identityDocumentValidity_A {
  width:           100%;
  display:         flex;
  grid-column:     4/9;
  grid-row:        9;
}

.layout_identityDocumentValidity_B {
  width:           100%;
  display:         flex;
  grid-column:     4/9;
  grid-row:        8;
}

.layout_nationality_A {
  width:           100%;
  display:         flex;
  grid-column:     9/13;
  grid-row:        9;
}

.layout_nationality_B {
  width:           100%;
  display:         flex;
  grid-column:     9/13;
  grid-row:        8;
}

.layout_contactRequest_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        10;
}

.layout_contactRequest_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        9;
}

.layout_contactRequest_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        9;
}

.layout_contactRequest_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        8;
}

.layout_termsOfServiceConsent_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        11;
}

.layout_termsOfServiceConsent_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        10;
}

.layout_termsOfServiceConsent_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        10;
}

.layout_termsOfServiceConsent_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        9;
}

.layout_depositConsent_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        12;
}

.layout_depositConsent_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        11;
}

.layout_depositConsent_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        11;
}

.layout_depositConsent_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        10;
}

.layout_rgpdConsent_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        13;
}

.layout_rgpdConsent_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        12;
}

.layout_button_A_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        14;
}

.layout_button_A_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        13;
}

.layout_button_B_1 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        13;
}

.layout_button_B_2 {
  width:           100%;
  display:         flex;
  grid-column:     1/13;
  grid-row:        12;
}

/*******/


.buttons {

  margin: auto;
}

.link {

  color: #FFFFFF;
}

.buttons_block {
  margin: auto;
}



.grid_depositConsent{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_termsOfServiceConsent{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_contactRequest{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.grid_rgpdConsent{
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.text_depositConsent {

  color: #FFFFFF;
  text-align: justify;
}

.grid_termsOfServiceConsent {

  color: #FFFFFF;
  text-align: justify;
}

.text_contactRequest {

  color: #FFFFFF;
  text-align: justify;
}

.text_rgpdConsent {

  color: #FFFFFF;
  text-align: justify;
}

.btn {

 width: fit-content;
 margin: auto;
 margin-top: 10px;
}

.grid_info
 {
  width:                 100%;
  pading:                0px;
  margin:                0;
  font-size:             16px;
  text-align:            left;

  display:               grid;
  grid-template-columns: 170px auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}


.termsOfServiceWindow {

  width:   800px;
  max-width: 95%;
  height:   600px;
  max-width: 95%;
  padding:  0px;
  overflow: hidden;

}

.termsOfServiceDiv {

  height:   599px;
  width:   790;
  overflow: auto;
  margin: auto;
  padding:  5px;

}

.termsOfServiceWindow_text {

 text-align: justify;
 padding-bottom: 10px;
}

.termsOfServiceWindow_titre1 {

 text-weight: bold;
 text-size: 20px;
 width: fit-content;
 margin: auto;
}

.termsOfServiceWindow_titre2 {

 text-weight: bold;
 text-size: 18px;
}
