.root {
  color:       #FFFFFF;
  max-width: 1585px;
  margin: auto;
}

.title{
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.layout_1 {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto 600px;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.layout_1_block_text {
  grid-column:     1;
  grid-row:        1;
}

.layout_1_block_form {
  grid-column:     2;
  grid-row:        1;
}


.layout_2 {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.layout_2_block_text {
  grid-column:     1;
  grid-row:        1;
}

.layout_2_block_form {
  grid-column:     1;
  grid-row:        2;
}

.image {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: auto;
}


.btn {

 width: fit-content;
 margin: auto;
 margin-top: 10px;
}

.description {

  padding-left: 10px;
}


.block_sup {
  width:                 100%;
  pading:                0;
  margin:                0;

  display:               grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

.block_sup1 {
  grid-column:     1;
  grid-row:        1;
}

.block_sup2 {
  grid-column:     2;
  grid-row:        1;
}
