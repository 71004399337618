.root {
}

.date_selector
 {
  width:           100%;
  padding:         0;
  margin:          0;
  padding:         0px;
  box-sizing:      border-box;
  
  display:               grid;
  grid-template-columns: 3fr 3fr 4fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}

.day_block
 {
  grid-column: 1;
  grid-row:    1;
}

.month_block
 {
  grid-column: 2;
  grid-row:    1;
}

.year_block
 {
  grid-column: 3;
  grid-row:    1;
}
